'use client';

import { useLayoutEffect, useReducer } from 'react';

const INIT = 'INIT';
const ERROR = 'ERROR';
const GET_CSS_CUSTOM_PROPERTY = 'GET_CSS_CUSTOM_PROPERTY';
const SET_CSS_CUSTOM_PROPERTY = 'SET_CSS_CUSTOM_PROPERTY';

type Init = {
  type: typeof INIT;
};

type Get = {
  propertyValue: string;
  selectedElement: HTMLElement;
  type: typeof GET_CSS_CUSTOM_PROPERTY;
};

type Set = {
  propertyValue: string;
  selectedElement: HTMLElement;
  type: typeof SET_CSS_CUSTOM_PROPERTY;
};

type Error = {
  error: string;
  type: typeof ERROR;
};

type ComputedStyleAction = Init | Get | Set | Error;

type ComputedStyleState = {
  error?: string;
  propertyName: string;
  propertyValue: string;
  selectedElement: HTMLElement | null;
  status: 'INIT' | 'ERROR' | 'OK';
};

const computedStyleReducer = (
  state: ComputedStyleState,
  action: ComputedStyleAction
): ComputedStyleState => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        status: 'INIT',
      };
    case 'GET_CSS_CUSTOM_PROPERTY':
      return {
        ...state,
        propertyValue: action.propertyValue.trim(),
        selectedElement: action.selectedElement,
        status: 'OK',
      };
    case 'SET_CSS_CUSTOM_PROPERTY':
      return {
        ...state,
        propertyValue: action.propertyValue,
        status: 'OK',
      };
    case 'ERROR':
      return {
        ...state,
        error: action.error,
        propertyName: '',
        propertyValue: '',
        selectedElement: null,
        status: 'ERROR',
      };

    default:
      return state;
  }
};

// @see https://github.com/JCofman/react-use-css-custom-property
export const useCssVariable = (
  propertyName: string,
  querySelector: string = 'body'
): [ComputedStyleState, (value: string) => void] => {
  const initialState: ComputedStyleState = {
    propertyName,
    propertyValue: '',
    selectedElement: null,
    status: 'INIT',
  };

  const [computedStyleState, dispatch] = useReducer(computedStyleReducer, initialState);

  const setCSSCustomProperty = (value: string) => {
    if (computedStyleState.selectedElement && computedStyleState.propertyName) {
      computedStyleState.selectedElement.style.setProperty(computedStyleState.propertyName, value);
      dispatch({
        propertyValue: value,
        selectedElement: computedStyleState.selectedElement,
        type: 'SET_CSS_CUSTOM_PROPERTY',
      });
    }
  };

  useLayoutEffect(() => {
    const selectedElement = document.querySelector(querySelector) as HTMLElement;

    if (selectedElement) {
      const computedPropertyValue = window
        .getComputedStyle(selectedElement)
        .getPropertyValue(propertyName);

      if (computedPropertyValue) {
        dispatch({
          propertyValue: computedPropertyValue,
          selectedElement,
          type: 'GET_CSS_CUSTOM_PROPERTY',
        });
      } else {
        dispatch({
          error: `No property value for ${propertyName} on element ${querySelector} found.`,
          type: 'ERROR',
        });
      }
    } else {
      dispatch({
        error: `There is no element that matches the ${querySelector} query.`,
        type: 'ERROR',
      });
    }
  }, [computedStyleState.propertyValue, querySelector]);

  return [computedStyleState, setCSSCustomProperty];
};
