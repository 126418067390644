/* eslint-disable id-length */
import { type Plugin } from 'chart.js';

import { type DonationLevel } from '@shared/utils';

export type NextClosestLevelPlugin = {
  color: string;
  font: string;
  lineColor: string;
  nextClosestLevel: DonationLevel | null;
  tickLength: number;
};

export type HorizontalBackgroundPlugin = {
  defaultBarPercentage: number;
  defaultCategoryPercentage: number;
  fillColor: string;
};

export const getCustomPlugins = () => {
  const horizontalBackgroundPlugin: Plugin<'bar', HorizontalBackgroundPlugin> = {
    beforeDatasetDraw(chart, _, plugins) {
      const {
        chartArea: { height, left, width },
        ctx,
        data,
        scales: { y },
      } = chart;
      const { defaultBarPercentage, defaultCategoryPercentage } = plugins;

      const barPercentage = data.datasets[0].barPercentage || defaultBarPercentage;
      const categoryPercentage = data.datasets[0].categoryPercentage || defaultCategoryPercentage;
      const barThickness = height * barPercentage * categoryPercentage;

      ctx.save();
      ctx.fillStyle = plugins.fillColor;
      ctx.fillRect(left, y.getPixelForValue(0) - barThickness / 2, width, barThickness);
      ctx.restore();
    },
    id: 'horizontalBackgroundPlugin',
  };

  const nextClosestLevelPlugin: Plugin<'bar', NextClosestLevelPlugin> = {
    afterDatasetDraw(chart, _, plugins) {
      const { color, font, tickLength, lineColor, nextClosestLevel } = plugins;

      if (!nextClosestLevel) return;

      const {
        chartArea: { bottom, top },
        ctx,
        scales: { x },
      } = chart;
      const nextClosestLevelPositionX = x.getPixelForValue(nextClosestLevel.amount);

      ctx.save();
      ctx.strokeStyle = lineColor;
      ctx.translate(0.5, 0.5);
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(nextClosestLevelPositionX, top - tickLength - 5);
      ctx.lineTo(nextClosestLevelPositionX, bottom + tickLength);
      ctx.stroke();
      ctx.restore();

      ctx.save();
      ctx.fillStyle = color;
      ctx.font = font;
      ctx.textAlign = 'right';
      ctx.fillText(nextClosestLevel.name, nextClosestLevelPositionX - 5, top - 5);
      ctx.restore();
    },
    id: 'nextClosestLevelPlugin',
  };

  return [horizontalBackgroundPlugin, nextClosestLevelPlugin];
};
