'use client';

import { type ChangeEvent, useState } from 'react';

import { ALLOWED_EXTENSIONS, MAX_FILE_SIZE } from '../constants';

export const useProfilePictureUpload = ({
  fileSizeErrorMessage,
  fileTypeErrorMessage,
  initialProfilePicture,
}: {
  fileSizeErrorMessage: string | null | undefined;
  fileTypeErrorMessage: string | null | undefined;
  initialProfilePicture: string | undefined;
}) => {
  const [previewPictureSource, setPreviewPictureSource] = useState(initialProfilePicture);

  const updatePreviewImageSource = (event: ChangeEvent<HTMLInputElement> | undefined) => {
    if (event && event.target?.files?.length) {
      const imageURL = URL.createObjectURL(event.target.files[0]);
      setPreviewPictureSource(imageURL);
      return;
    }

    setPreviewPictureSource(undefined);
  };

  const rules = {
    validate: {
      acceptedFormats: (file: FileList) => {
        if (!file?.[0]) {
          return true;
        }

        const isValidFileExtension = ALLOWED_EXTENSIONS.includes(
          file[0]?.type?.split('/')?.pop() ?? ''
        );

        return isValidFileExtension || (fileTypeErrorMessage ?? 'Incorrect file format.');
      },
      lessThan5MB: (file: FileList) => {
        if (!file?.[0]) {
          return true;
        }

        const isValidFileSize = file[0]?.size < MAX_FILE_SIZE;

        return isValidFileSize || (fileSizeErrorMessage ?? 'The image is too big or empty.');
      },
    },
  };

  return {
    previewPictureSource,
    rules,
    updatePreviewImageSource,
  };
};
