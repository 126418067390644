'use client';

import classNames from 'classnames';
import { useEffect } from 'react';
import { FocusScope } from 'react-aria';

import { MdrtLogo } from '../svgs';

import styles from './loader.module.scss';

type LoaderProps = {
  allowScroll?: boolean;
  className?: string;
  fixedToViewportCenter?: boolean;
  isInner?: boolean;
  subtitle?: string;
  title?: string;
};

export const Loader = ({
  allowScroll,
  className,
  fixedToViewportCenter,
  isInner,
  subtitle,
  title,
}: LoaderProps) => {
  const withText = Boolean(subtitle) || Boolean(title);

  useEffect(() => {
    if (allowScroll) {
      return () => undefined;
    }

    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [allowScroll]);

  return (
    <FocusScope autoFocus contain restoreFocus>
      <div
        aria-busy="true"
        aria-label="Loading, please wait"
        aria-live="polite"
        aria-valuetext="Loading, please wait"
        className={classNames(styles['loader'], className, {
          [styles['loader--with-text']]: withText,
          [styles['loader--is-inner']]: isInner,
        })}
        data-testid="loader"
        role="progressbar"
        tabIndex={0}
      >
        <div
          className={classNames(styles['loader__logo-container'], {
            [styles['loader__logo-container--centered']]: fixedToViewportCenter,
          })}
        >
          <MdrtLogo className={styles['loader__logo']} />
          <div className={styles.loader__spinner}>
            <svg viewBox="22 22 44 44">
              <circle cx="44" cy="44" fill="none" r="20" strokeWidth="2" />
            </svg>
          </div>
        </div>
        {withText && (
          <div className={styles['loader__text-container']}>
            {title && <h2>{title}</h2>}
            {subtitle && <h3>{subtitle}</h3>}
          </div>
        )}
      </div>
    </FocusScope>
  );
};
