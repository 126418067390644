export const GTM_EVENT_USER_INTERACTIONS = 'user_interactions';
export const GTM_ACTION_CLICK = 'click';
export const GTM_EVENT_NAME_APP_DOWNLOAD = 'app_download';
export const GTM_EVENT_NAME_PHONE_CALL = 'phone_call';
export const GTM_EVENT_LABEL_IOS_APP = 'ios_app';
export const GTM_EVENT_LABEL_ANDROID_APP = 'android_app';
export const GTM_EVENT_LOGIN = 'login';
export const GTM_EVENT_NAME_SUCCESSFUL_SIGNIN = 'successful_signin';
export const GTM_ACTION_FORM_SUBMISSION = 'form_submission';
export const GTM_EVENT_NAME_SOCIAL_ICONS = 'social_icons';
export const GTM_EVENT_NAME_OUTBOUND = 'outbound';
export const GTM_EVENT_NAME_LEARN_SHARE = 'learn_share';
export const GTM_EVENT_PAGE_VIEW = 'pageView';
export const GTM_EVENT_NAME_INTERNAL_LINK_CLICK = 'internal_link_click';
export const GTM_EVENT_NAME_EXPAND_SECTION = 'expand_section_click';
export const GTM_EVENT_NAME_FAQS_VIEWED = 'faqs_viewed';
export const GTM_EVENT_NAME_PDF_DOWNLOAD = 'pdf_download';
export const GTM_ACTION_DOWNLOAD = 'download';
export const GTM_EVENT_NAME_SLIDER_NAV_CLICK = 'slider_nav_click';
export const GTM_EVENT_NAME_ASSESSMENT_SCORE_CHANGE = 'assessment_score_change';
