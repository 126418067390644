import { sendGTMEvent } from '@next/third-parties/google';

import { GTM_ACTION_CLICK, GTM_EVENT_USER_INTERACTIONS } from '../constants';

type UserInteractionsEventType = {
  action?: string;
  event?: string;
  eventName?: string;
  label?: string;
  link_label?: string;
  new_value?: number;
  old_value?: number;
};

export const sendGtmEvent = ({
  action,
  event,
  eventName,
  label,
  link_label,
  new_value,
  old_value,
}: UserInteractionsEventType) =>
  sendGTMEvent({
    action: action || GTM_ACTION_CLICK,
    event: event || GTM_EVENT_USER_INTERACTIONS,
    eventName: eventName || undefined,
    label: label || undefined,
    link_label: link_label || undefined,
    new_value: new_value ?? undefined,
    old_value: old_value ?? undefined,
  });
